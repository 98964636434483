import React, { useState, useRef, useEffect } from "react";
// import Helmet from 'react-helmet';
// import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";

import { Link } from "gatsby";

import { List, ListItem, ListItemText } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";


import Grid from "@material-ui/core/Grid";
import NiinaHeader from "../components/NiinaHeader";
import NiinaFooter from "../components/NiinaFooter";

import { Helmet } from "react-helmet-async";

function Index() {
  // const classes = useStyles();

  return (
    <div style={{ minWidth: "320px" }}>
      <Helmet>
        <html lang="ja" />
        <title>
          焼き立ての「いいな」を届けるパン屋 『Bakery
          Niina(ベーカリー・ニーナ)』 | 店舗情報
        </title>
        <meta
          name="description"
          content="山形のみなさんに、パン職人が一晩かけて仕込むこだわりパンを一番おいしい状態で届けたい。焼き立てパンが香る店『Bakery Niina（ベーカリーニーナ）』です。多い日には１日５０回焼き上げるため、いつでも焼きたてのパンが楽しめます。"
        />
      </Helmet>

      <NiinaHeader />
      <AboutComponent />
      <NiinaFooter />
    </div>
  );
}

export default Index;

function AboutComponent() {
  return (
    <div className="App">
      <div
        style={{
          display: "row",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            marginTop: "1em",
            minWidth: "160px",
            maxWidth: "30%",
          }}
          src="../images//img/photos/ヘッダーロゴ@3x.png"
        />
      </div>

      <div
        style={{
          marginTop: "3.2em",
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{ minWidth: "160px", maxWidth: "70%" }}
          src="../images//img/3x/アセット 12@3x.png"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: "3.125vw",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            minWidth: "160px",
            maxWidth: "70%",
            marginBottom: "1.8em",
          }}
          src="../images//img/photos/shop_front.jpg"
        />
      </div>

      <div
        style={{
          display: "row",
          fontSize: mainFontSize,
          margin: "1.8em",
          textAlign: "left",
        }}
      >
        <p>Bakery Niina (ベーカリーニーナ)</p>
        <p>営業時間 7:00 ～ 18:00 (売り切れ次第終了)</p>
        <p>定休日　火曜日</p>
        <p>住所 990-0823 山形県山形市下条町 2-3-22</p>
        <p>TEL. 023-676-8899</p>
        <p>クレジットカード可</p>
      </div>

      <div
        style={{
          display: "row",
          fontSize: "3.125vw",
          paddingBottom: "1.3em",
        }}
      >
        <StaticImage
          placeholder="none"
          style={{
            minWidth: "160px",
            maxWidth: "70%",
          }}
          src="../images//img/photos/parking@3x.png"
        />
        <div style={{ display: "row", fontSize: mainFontSize }}>駐車場</div>
      </div>
      <iframe
        style={{
          width: "100vw",
          height: "45vh",
          frameBordeer: 0,
          allowfullScreen: "",
          style: "border:0",
          paddingBottom: "1.3em",
        }}
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12531.0062887557!2d140.324449!3d38.2620674!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7787545aec4e84eb!2sBakery%20Niina!5e0!3m2!1sja!2sjp!4v1592272678347!5m2!1sja!2sjp"
      ></iframe>
    </div>
  );
}

const mainFontSize = "3.8vw";
